









import Vue from 'vue';
import HeaderLayoutComponent from '@/components/layouts/header.vue';
import FooterLayoutComponent from '@/components/layouts/footer.vue';

export default Vue.extend({
  components: {
    headerLaoyout: HeaderLayoutComponent,
    footerLayout: FooterLayoutComponent,
  },
  created(): void {
    document.title = this.$t('pageTitle') as string;
  },
});
