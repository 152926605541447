




























































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Privacy extends Vue {
  created() {
    document.title = `${this.$route.meta.title} | Simetrik`;
  }
}
