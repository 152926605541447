import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeComponent from './views/Home.vue';
import AgreementComponent from './views/AgreementComponent.vue';
import PrivacyComponent from './views/PrivacyComponent.vue';
import SpherPrivacyComponent from './views/SpherPrivacyComponent.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    { path: '/', component: HomeComponent },
    { path: '/agreement', component: AgreementComponent, meta: { title: 'Пользовательское соглашение' } },
    { path: '/privacy', component: PrivacyComponent, meta: { title: 'Политика конфиденциальности' } },
    { path: '/spher-privacy', component: SpherPrivacyComponent },
  ],
  mode: 'history',
});

export default router;
