





















































export default {
  name: 'workmap',
};
