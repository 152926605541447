




























export default {
  name: 'stacks',
};
