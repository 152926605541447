





























import Vue from 'vue';

export default Vue.extend({
  name: 'services',
  data() {
    return {
      serviceData: [
        { title: this.$t('services.first.title'), description: this.$t('services.first.text') },
        { title: this.$t('services.second.title'), description: this.$t('services.second.text') },
        { title: this.$t('services.third.title'), description: this.$t('services.third.text') },
      ],
    };
  },
});
