import { render, staticRenderFns } from "./PrivacyComponent.vue?vue&type=template&id=0b11e929&scoped=true&"
import script from "./PrivacyComponent.vue?vue&type=script&lang=ts&"
export * from "./PrivacyComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b11e929",
  null
  
)

export default component.exports