



















import { Component, Vue } from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import Hero from '@/components/front/hero.vue';
import Services from '@/components/front/services.vue';
import Workmap from '@/components/front/workmap.vue';
import Stacks from '@/components/front/stacks.vue';
import Feedback from '@/components/front/feedback.vue';

@Component({
  components: {
    Hero,
    Services,
    Workmap,
    Stacks,
    Feedback,
    HelloWorld,
  },
})
export default class Home extends Vue {}
