



































































































import { Component, Vue } from 'vue-property-decorator';
/* eslint-disable */
import { required, email, minValue } from "vuelidate/lib/validators";

@Component({
  name: "feedback",
  validations: {
    subject: { required, minValue: minValue(1) },
    name: { required },
    phone: { required },
    email: { required, email },
  },
})
export default class Feedback extends Vue {
  subject = 0;
  name = "";
  phone = "";
  email = "";
  isPrivacyConfirmed = true;

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return false;
    }

    const dataParams = {
      subject: +this.subject,
      name: this.name,
      phone: this.phone,
      email: this.email,
    };

    this.$http.post("https://api.simetrik.ru/feedback", dataParams).then(
      (res) => {
        this.$bvModal.show("successModal");
        this.clearForm();
      },
      (err) => {
        // eslint-disable-next-line no-alert
        alert(this.$t("sendError"));
        console.error(err);
      }
    );
  }

  clearForm() {
    this.subject = 0;
    this.name = "";
    this.phone = "";
    this.email = "";
    this.$v.$reset();
  }
}
