/* eslint-disable no-restricted-syntax */
import supportedLocales from '@/config/supported-locales';

interface StringMap { [key: string]: string }

const _supportedLocales: StringMap = supportedLocales;

export function getSupportedLocales() {
  const annotatedLocales: Array<any> = [];
  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({ code, name: _supportedLocales[code] });
  }

  return annotatedLocales;
}

export function supportedLocalesInclude(locale: any) {
  return Object.keys(supportedLocales).includes(locale);
}
