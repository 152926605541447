import Vue from 'vue';
import Resource from 'vue-resource';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueScrollTo from 'vue-scrollto';
import router from './router';
import store from './store';
import i18n from './i18n';
import App from './App.vue';

Vue.use(Resource);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -76,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Vue.use(Vuelidate as any);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
