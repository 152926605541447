



























import Vue from 'vue';

export default Vue.extend({
  name: 'hero',
  data(): any {
    return {
      title: this.$t('hero.title'),
      subtitle: this.$t('hero.subtitle'),
    };
  },
});
