




























import Vue from 'vue';

export default Vue.extend({
  data(): any {
    return {
      isHideOrderButton: false,
    };
  },
  created() {
    const { path } = this.$route;
    this.isHideOrderButton = path.match('agreement') !== null || path.match('privacy') !== null;
  },
  watch: {
    $route(to, from) {
      if (to.path === '/') {
        this.isHideOrderButton = false;
      }
    },
  },
});
